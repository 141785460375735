import { AlertContext, Builder_, Cta_, H2, H3, H5, Input_, Main_, P, device } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet';
import { LandingInterest, LandingInterestAdSpend, LandingInterestAdSpendGrid, LandingInterestAdSpendImg, LandingInterestAdSpendText, LandingInterestForm, LandingInterestFormWrapper, LandingInterestHeader, LandingInterestHeaderHero, LandingInterestHeaderWrapper, LandingInterestHelpAction, LandingInterestHow, LandingInterestHowCard, LandingInterestHowCardLast, LandingInterestHowGrid, LandingInterestPricing, LandingInterestPricingBox, LandingInterestPricingGrid, LandingInterestQa, LandingInterestQaA, LandingInterestQaBox, LandingInterestQaGrid, LandingInterestQaQ, LandingInterestQuestions, LandingInterestQuestionsImgs, LandingInterestQuestionsText, LandingInterestSuccess, LandingInterestWhyBox, LandingInterestWhyGrid, LandingTestimonials, LandingTestimonialsBox, LandingTestimonialsBoxImg, LandingTestimonialsWrapper } from './DAILYPROFIT_INTEREST.css'
import Header_Dailyprofit from './components/Header_Dailyprofit'
import { Home_Pricing } from './components/Home_Pricing'
import { AuthContext } from '../../services/authentication/Guard'
import store from '../../redux/store';
import zoe from '../../assets/landingQuestad/home/zoe.jpg'
import katerina from '../../assets/landingQuestad/home/katerina.jpg'
import sophie from '../../assets/landingQuestad/home/sophie.webp'


export default function DAILYPROFIT_INTEREST() {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const {lang} = useParams();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth?.authenticated) {
        navigate(`/${lang}/questad-interest/${store.getState()?.user?.user?.sub}`);
    }
  },[auth?.authenticated])

  return (
    <LandingInterest>
        <Helmet>
        {/* Google Tag Manager script */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_QUESTAD_GOOGLE_ANALYTICS}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_QUESTAD_GOOGLE_ANALYTICS}');
          `}
        </script>
        {/* Google Tag Manager noscript */}
        {/* <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_QUESTAD_GOOGLE_ANALYTICS}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript> */}
      </Helmet>
    <LandingInterestHeader>
        <Header_Dailyprofit/>
    </LandingInterestHeader>
        <LandingInterestQuestionsText>
            <H2>{t('You answer questions, we run ads!')}</H2>
            <P>{t('Just answer a few easy questions about your business, and our algorithm takes over!  Crafting automated digital marketing strategies for optimal performance depending your answers. We create campaigns, we adjust campaigns in retargeting your audience based on landing page visits and social engagement, all automatically without the need for marketing expertise by your side.')}</P>
        </LandingInterestQuestionsText>  
      <LandingInterestWhyGrid>
          <LandingInterestWhyBox>
              <h3>{t('Landing Page Creator with Lead Capture Form')}</h3>
              <p>{t("Effortlessly create high-converting landing pages. Integrated forms for capturing prospect leads - no design expertise required.")}</p>
          </LandingInterestWhyBox>
          <LandingInterestWhyBox>
              <h3>{t('No expertise needed for Ad Creation')}</h3>
              <p>{t('Create effective ads easily with our user-friendly ad builder. Marketing skills are not necessary, just focus on your message and leave everything to us.')}</p>
          </LandingInterestWhyBox>
          <LandingInterestWhyBox>
              <h3>{t('Real-Time Analytics Display')}</h3>
              <p>{t('Seamless integration with your Facebook business accounts.')}<br/>
                    {t('What can you track?')}<br/>
                    {t('Reach - Monitor how far your ads are going.')}<br/>
                    {t('Clicks - See how many users are engaging with your ads.')}<br/>
                    {t('Leads - Keep tabs on the leads generated from your campaigns.')}<br/>
                    {t('Landing Page Views - Understand the performance of your landing pages.')}<br/> 
                    </p>
          </LandingInterestWhyBox>
      </LandingInterestWhyGrid>
      <LandingInterestAdSpend>
        <LandingInterestAdSpendGrid>
            <LandingInterestAdSpendText>
              <H2 className='gradientText'>{t('Strategic')} <br/> {t('Ad Spend Allocation')}</H2>
              <H5>{t('Our app optimizes social media ad campaigns by analyzing your answers on the questionnaires. Depending on your main sales objective & budget Questad tailors strategies to reach your target audience and goals effectively. Using advanced algorithms, it considers factors like audience demographics and past performance to maximize your ad spend.')}</H5>
            </LandingInterestAdSpendText>
          
            <LandingInterestAdSpendImg>
              <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/TimeSaving.svg' />
            </LandingInterestAdSpendImg>
            </LandingInterestAdSpendGrid>
      </LandingInterestAdSpend>
    <Home_Pricing />

    <LandingTestimonials>
        <LandingTestimonialsWrapper>
        <H2>{t('Success Stories')}</H2>


            <LandingTestimonialsBox>
                <LandingTestimonialsBoxImg>
                    <img src={katerina} />
                    <div>
                        <h4>{t('Katerina Dysseaki')}</h4>
                        <p>Gata Negra Spanish Online</p>
                    </div>
                </LandingTestimonialsBoxImg>
                <H5>"{t("I have no words for how much Questad has helped me with my Facebook campaign as a Spanish teacher. The first campaign I did for a month with €50, brought me 27 contacts with prospective students. I had done the exact same campaign (verbal, video, targeting and €60/30 days) immediately before my collaboration with Doitforme alone, through the Facebook platform, resulting in 4 spam messages, about 30 likes from random names and none(!) contact with a prospective student. Now 3 days ago I did a second campaign with 21 contacts so far!! -this time alone, without the help of Luigi, who was very helpful the first time, step by step, via video call he guided me to set it up. I highly recommend working with Doitforme! It is an investment in the growth of any business, especially for an individual service provider like mine.")}"</H5>
            </LandingTestimonialsBox>

            <LandingTestimonialsBox>
                
                <LandingTestimonialsBoxImg>
                    <img src={zoe} />
                    <div>
                        <h4>{t('Zoe Papandreou')}</h4>
                        <p>La tienda de la vita</p>
                    </div>
                </LandingTestimonialsBoxImg>
                <H5>"{t("I started running ads for my eshop through Questad. I saw a difference in results from the very first month. My turnover skyrocketed and because of the results reports the app sends every week I was able to accurately measure my refund on advertising expense.")}"</H5>
            </LandingTestimonialsBox>
            <LandingTestimonialsBox>
                
                <LandingTestimonialsBoxImg>
                    <img src={sophie} />
                    <div>
                        <h4>{t('Sophia Diamantea')}</h4>
                        <p>Sophie’s Lemonade</p>
                    </div>

                </LandingTestimonialsBoxImg>

                <H5>"{t("Questad has solved all my problems in finding new clients. It saved me valuable time and I managed to have clients in areas that I would not have been able to reach easily. It also helped me to get to know my work a lot more people than if I had done an advertisement on social media by myself. Very easy to use, impressively efficient and excellent technical support from the questad team. Extra bonus tips and guidance for more effective ads!")}"</H5>
            </LandingTestimonialsBox>
        </LandingTestimonialsWrapper>

        </LandingTestimonials>
      <LandingInterestQa>
            <H2>{t("Q&A")}</H2>
            <LandingInterestQaGrid>
                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("Do I Pay a Single Fee Every Month?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, you pay just one monthly fee for using our platform. There are no hidden charges.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>
                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("Is the Monthly Charge on My Card Including Your Subscription?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, the amount charged to your card includes the subscription fee for using our platform.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>
                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("Can I Get a Refund?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("  You can get a refund within the first 14 days on your subscription fee and any unspent ad budget. However, funds already spent on Meta or Google Ads cannot be refunded.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>
                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("Is There Chat Support Available?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, we offer chat support. Any queries you have will be turned into tickets using your registered email.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>
                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("Can I Control My Ads Through QuestAd?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Absolutely! You can edit the visuals and captions of your ads through QuestAd.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>

                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("Can I Stop My Subscription Immediately?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Yes, you can stop your subscription at any time. QuestAd will ask if you want your ads to keep running for the remaining period of your subscription. If you choose to stop your ads, you can only reactivate them during your subscription days. The ad budget will continue to be used for the active days.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>
                <LandingInterestQaBox>
                    <LandingInterestQaQ>
                        <H3>{t("How Does QuestAd Help Simplify Ad Management for Small Businesses?")}</H3>
                    </LandingInterestQaQ>
                    <LandingInterestQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("QuestAd automates and simplifies the ad management process, making it easy for small businesses to run and optimize campaigns without needing extensive advertising expertise.")}</h6>
                    </LandingInterestQaA>
                </LandingInterestQaBox>
            </LandingInterestQaGrid>
        </LandingInterestQa>
        <LandingInterestHow>
        <H2>{t("How it works")}</H2>
        <LandingInterestHowGrid>
            <LandingInterestHowCard>
                <aside>
                    <h4>1</h4>
                </aside>
                <main>
                    <H3>{t("Questionnaire")}</H3>
                    <P>{t("Our app starts by getting to know your business and goals. Answer a few simple questions, and our algorithm will generate tailored campaign options just for you.")}</P>
                </main>
            </LandingInterestHowCard>
            <LandingInterestHowCard>
                <aside>
                    <h4>2</h4>
                </aside>
                <main>
                    <H3>{t("Create")}</H3>
                    <P>{t("Once you've answered the questionnaire, it's time to bring your campaign to life. Use our user-friendly ad builder to create stunning ads that resonate with your audience.")}</P>
                </main>
            </LandingInterestHowCard>
            <LandingInterestHowCardLast>
                <div className='LandingInterestHowCardSplit'>
                    <aside>
                        <h4>3</h4>
                    </aside>
                    <main>
                        <H3>{t("Run ads")}</H3>
                        <P>{t("With just a few clicks, you can launch your campaign and start reaching your target audience. Our app leverages advanced algorithms to analyze your ad spend and determine the most effective strategy for running ads, ensuring maximum impact.")}</P>
                    </main>
                </div>
            </LandingInterestHowCardLast>
        </LandingInterestHowGrid>
        {/* <Cta_ size="large" text={t("Start Now")} onClick={()=> navigate(`/${lang}`)}/> */}
    </LandingInterestHow>
    </LandingInterest>





  )
}
